<template>
  <div class="ingedients-popup">
    <div class="container">
      <div class="overflow-y-scroll table-wrapper">
        <Table :table-headers="tableHeaders" :table-data-rows="tableDataRows" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Table: () => import(/* webpackPrefetch: true */ "../common/Table"),
  },
  props: {
    tableDataRows: {
      type: Array,
    },
  },
  data: () => ({
    isLoader: true,
    tableHeaders: [
      {
        INCIName: "",
        IngredientFunction: "",
        Inclusion: "",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  max-height: 315px;
  overflow: auto;
}
</style>
