var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ingedients-popup" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "overflow-y-scroll table-wrapper" },
        [
          _c("Table", {
            attrs: {
              "table-headers": _vm.tableHeaders,
              "table-data-rows": _vm.tableDataRows
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }